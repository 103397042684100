/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @font-face {
//   font-family: mont;
//   src: url(assets/fonts/mont.ttf);
// }

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito-Regular"),
    url(assets/fonts/Nunito/Nunito-Regular.ttf)
}


* {
  font-family: Nunito;
}

ion-modal.modalContact {
  .modal-wrapper {
    width: 90% !important;
    border-radius: 5px;
  }
  &.bottom {
    align-items: flex-end;
  }
  --height: auto;
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      overflow: auto;
      // padding: 10px;
      // border-radius: 10px;
    }
  }
}

ion-modal.custom_modal2 {
  .modal-wrapper {
    width: 100% !important;
    // border-top-left-radius: 25px;
    max-width: 800px !important;

    // border-top-right-radius: 25px;
  }
  &.bottom {
    align-items: flex-end !important;
  }
  // --height: 70vh;
  align-items: flex-end;
}

.mod-search-results {
  .modal-wrapper {
    background-color: transparent;
  }
}
.swal2-popup {
  padding: 5px !important;
}
.header-class {
  border-bottom: 1px solid #e6ebf1;
  padding: 5px;
}

.confirm-button-class {
  background-color: var(--ion-color-primary) !important;
}
.header-class {
  align-items: flex-start !important;
}
.title-class {
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 1.5 !important;
}
.actions-class {
  border-top: 1px solid #e6ebf1;
  padding: 5px !important;
  justify-content: end !important;
}
.content-class {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.63 !important;
  color: rgb(106, 105, 110) !important;
  margin: 10px !important;
}



.tabs-inner{
  contain: none !important;
  position: initial !important;
}

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

ion-tab-bar{
  --background: black;
}
ion-tab-button.tab-selected::before {
  background-color: #ff971e !important; 
  /* width: 50%; */
  /* margin: auto; */
  height: 4px !important;
  width: 40px !important;
}

ion-tab-button{
  // --color: var(--ion-color-medium);
  --color-selected: #ff971e !important;
  --color: white !important;
}